import React from 'react';
import SimpleLineIcon from 'react-simple-line-icons';

const DetailsList = ({ details }) => {
  const { dateFinished, client, categories, technologies, url, target } = details;

  const parseJson = object => {
    const nameArray = [];
    for (let i = 0; i < object.length; i++) {
      nameArray.push(object[i].name);
    }

    return nameArray.join(', ');
  };

  parseJson(categories);

  return (
    <ul className="details-list">
      <li className="details-list__item">
        <SimpleLineIcon name="calendar" />
        <div>
          <span>
            <strong>Date finished</strong>
          </span>
          <span>{dateFinished}</span>
        </div>
      </li>
      <li className="details-list__item">
        <SimpleLineIcon name="briefcase" />
        <div>
          <span>
            <strong>Client</strong>
          </span>
          <span>{client}</span>
        </div>
      </li>
      <li className="details-list__item">
        <SimpleLineIcon name="puzzle" />
        <div>
          <span>
            <strong>Used technologies</strong>
          </span>
          <span>{parseJson(technologies)}</span>
        </div>
      </li>
      <li className="details-list__item">
        <SimpleLineIcon name="folder" />
        <div>
          <span>
            <strong>Categories</strong>
          </span>
          <span>{parseJson(categories)}</span>
        </div>
      </li>
      <li className="details-list__item">
        <SimpleLineIcon name="link" />
        <div>
          <span>
            <strong>Url</strong>
          </span>
          <a href={target} target="_blank">
            {url}
          </a>
        </div>
      </li>
    </ul>
  );
};

export default DetailsList;
