import React from 'react';
import { Link } from 'gatsby';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from 'react-bootstrap';

const Title = ({ projectName }) => (
  <Row className="project__title-bar">
    <Container>
      <Row>
        <Col xs={12}>
          <h2>Portfolio</h2>
        </Col>
        <Col xs={12}>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/">Projects</Link>
            </li>
            <BreadcrumbItem active>{projectName}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </Container>
  </Row>
);

export default Title;
