import React from 'react';
import { Container } from 'react-bootstrap';
import SimpleReactLightbox from 'simple-react-lightbox';

import Layout from '../components/Layout';
import Details from '../components/Project/Details';
import Title from '../components/Project/Title';
import SEO from '../components/Seo';

const Project = ({ pageContext }) => {
  const projectData = pageContext.data;

  return (
    <SimpleReactLightbox>
      <Layout borderless>
        <SEO title={projectData.name} />
        <Title projectName={projectData.name} />
        <Container>
          <Details
            projectData={projectData}
            isLargeLayout={projectData.layout === 'lg'}
          />
        </Container>
      </Layout>
    </SimpleReactLightbox>
  );
};

export default Project;
