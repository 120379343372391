import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SRLWrapper } from 'simple-react-lightbox';

import DetailsList from './DetailsList';

const Details = ({ projectData, isLargeLayout = false }) => {
  const {
    img,
    name,
    description,
    dateFinished,
    client,
    categories,
    technologies,
    url,
    target,
    galleryImgs,
  } = projectData;

  const detailsListData = {
    dateFinished,
    client,
    url,
    target,
    categories,
    technologies,
  };

  return (
    <Row
      className={`project__detail${
        isLargeLayout ? ' project__detail--large' : ''
      }`}
    >
      <Col xs={12} lg={isLargeLayout ? 12 : 8}>
        <SRLWrapper>
          <Row>
            <Col xs={12} className="project__detail__img">
              <img src={`/images/${img}`} alt={name} />
            </Col>
            <Col xs={12}>
              <Row className="lightbox__content">
                {galleryImgs.map((item, i) => (
                  <Col xs={4} className={'lightbox__img'} key={`img${i}`}>
                    <img
                      src={`/images/${item.img}`}
                      alt={item.text}
                      key={`img${i}`}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </SRLWrapper>
      </Col>
      <Col xs={12} lg={isLargeLayout ? 12 : 4}>
        <Row>
          <Col
            xs={12}
            lg={isLargeLayout ? 6 : 12}
            className="project__detail__desc"
          >
            <h1>{name}</h1>
            <p>{description}</p>
            <DetailsList details={detailsListData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Details;
